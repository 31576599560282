<template>
    <div class="modal" v-if="modelValue" :class="{ active: modelValue }">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <div class="modal_info">
                    <h1>Import Template</h1>
                </div>
                <button class="close_btn left_out" @click="closeModal()" type="button"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <div class="modal_body">
                <div class="setting_wpr">
                    <div class="templates">
                        <div class="filter_bar">
                            <ul class="template_list">
                                <li v-if="templateType == 'email'">
                                    <div class="card_item active" @click="selectTemplate($event, 'email')">
                                        <!-- <img src="@/assets/images/mail.svg"> -->
                                        Email
                                    </div>
                                </li>
                                <li v-if="templateType == 'sms'">
                                    <div class="card_item active" @click="selectTemplate($event, 'sms')">
                                        <!-- <img src="@/assets/images/mail.svg"> -->
                                        SMS
                                    </div>
                                </li>
                                <li>
                                    <div class="card_item" @click="selectTemplate($event, 'email,sms')">
                                        <!-- <img src="@/assets/images/template.svg"> -->
                                        Multi-Channel
                                    </div>
                                </li>
                            </ul>
                            <div class="filter_item">
                                <multiselect
                                    v-model="params.category"
                                    :options="categories"
                                    value-prop="type"
                                    label="type"
                                    :searchable="true"
                                    placeholder="Select category"
                                    autocomplete="off"
                                ></multiselect>
                            </div>
                        </div>
                        <div class="filter_search">
                            <input type="text" placeholder="Search" ref="search" @input="isTyping = true" v-model.trim="params.search"/>
                            <button class="search_btn" type="button">
                                <i class="fas fa-search"></i>
                            </button>
                        </div>
                        <div v-if="templateAjaxLoader" class="loader-wpr">
                            <quote-loader />
                        </div>
                        <template v-else>
                            <ul class="card_list" v-if="templates.length">
                                <li v-for="(template, t) of templates" :key="t">
                                    <div class="content_card" @click="handleTemplateSelect(template)" :class="{ 'active': template.id == selectedTemplate.id }">
                                        <h5>{{template.title}}</h5>
                                        <div v-if="template.type == 'sms'" class="content" v-html="template.sms_content"></div>
                                        <div class="content" v-html="template.email_content"></div>
                                    </div>
                                </li>
                            </ul>
                            <div class="empty_box" v-else>
                                <img src="@/assets/images/empty_state.svg" alt="Empty">
                                <p class="text-center">No template found!</p>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <edit-template v-model="editTemplate" :template="selectedTemplate" :handle-import="templateImport" :template-type="templateType" />
</template>

<script>
    import { mapState, mapMutations, mapActions } from 'vuex'
    import { defineAsyncComponent } from 'vue'

    const EditTemplate = defineAsyncComponent(() => import('@/components/EditTemplate'))

    export default {
        name: 'Import Template',

        data () {
            return {
                params: { category: 0, search: '', type: 'email' },
                selectedTemplate: {},
                editTemplate: false,
                isTyping: false,
            };
        },

        props: {
            modelValue: Boolean,
            handleImport: Function,
            templateType: {
                type: String,
                default: 'email',
            },
        },

        emits: ['update:modelValue'],

        components: {
            EditTemplate,
        },

        watch: {
            modelValue (value) {
                const vm = this;

                if (value) {
                    // document.body.classList.add('modal-open');

                    if (vm.categories.length == 0) {
                        vm.getCategories();
                    }

                    if (!vm.params.category) {
                        vm.resetTemplates([]);
                    }
                } 
                // else {
                //     document.body.classList.remove('modal-open');
                // }
            },

            'params.category' (category) {
                const vm = this;

                vm.selectedTemplate = {};
                vm.getTemplates({ category, type: vm.params.type });
            },

            'params.search' (val) {
                const vm = this;

                setTimeout(() => {
                    vm.isTyping = false;
                }, 1500);
            },

            isTyping (val) {
                const vm = this;

                if (!val) {
                    if (vm.params.search != null) {
                        if (vm.params.search.length >= 2) {
                            vm.selectedTemplate = {};
                            vm.getTemplates({ category: vm.params.category, type: vm.params.type, search: vm.params.search });
                        }

                        if (vm.params.search.length === 0) {
                          vm.selectedTemplate = {};
                          vm.getTemplates({ category: vm.params.category, type: vm.params.type, search: vm.params.search });
                        }
                    }
                }
            },
        },

        computed: mapState({
            categories: state => state.templateModule.categories,
            templates: state => state.templateModule.templates,
            templateAjaxLoader: state => state.templateModule.templateAjaxLoader,
        }),

        mounted () {
            const vm = this;

            vm.params.type = vm.templateType;
        },

        methods: {
            ...mapActions({
                getCategories: 'templateModule/getCategories',
                getTemplates: 'templateModule/getTemplates',
            }),

            ...mapMutations({
                resetTemplates: 'templateModule/SET_TEMPLATES',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            templateImport (template) {
                const vm = this;

                vm.selectedTemplate = {};
                vm.editTemplate     = false;
                vm.handleImport(template);
                vm.closeModal();
            },

            handleTemplateSelect (template) {
                const vm = this;

                vm.selectedTemplate = template;
                vm.editTemplate     = true;
            },

            selectTemplate (e, type) {
                const vm = this;
                let temp = e.currentTarget;
                let allTemp = temp.closest('.template_list').querySelectorAll('.card_item');

                vm.params.type = type;

                for (let i = 0; i < allTemp.length; i++) {
                    allTemp[i].classList.remove('active');
                }

                if (temp.classList.contains('active')) {
                    temp.classList.remove('active');
                } else {
                    temp.classList.add('active');
                }

                if (vm.params.category) {
                    vm.getTemplates({ category: vm.params.category, type });
                }
            },
        },
    };
</script>

<style scoped>
    .templates {
        width: 100%;
        padding: 0;
    }

    .templates .template_list {
        list-style-type: none;
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        padding-top: 0;
    }

    .templates .template_list li {
        width: auto;
        padding: 0 25px 0 0;
    }

    .templates .template_list li .card_item {
        display: flex;
        flex-direction: row;
        align-content: center;
        align-items: center;
        padding: 7px 0;
        margin: 0;
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        color: #5a5a5a;
        border: 0;
        border-radius: 0;
        background: 0;
        position: relative;
        overflow: hidden;
    }
    .templates .template_list li .card_item:after{
        position: absolute;
        content: '';
        left: -100%;
        bottom: 0;
        width: 100%;
        border-bottom: 1px solid #5a5a5a;
        transition: all 0.3s ease-in-out;
    }
    .templates .template_list li .card_item:hover{
        box-shadow: none;
    }
    .templates .template_list li .card_item.active{
        color: #121525;
        font-weight: 500;
    }
    .templates .template_list li .card_item.active:after{
        left: 0;
    }

    .templates .template_list .card_item img {
        max-height: 20px;
        width: auto;
        margin: 0;
        margin-right: 10px;
    }

    .templates .filter_bar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
    }

    .templates .filter_item {
        flex: 0 1 165px;
    }

    .templates .filter_item .multiselect {
        min-height: 35px;
        border-radius: 20px;
        box-shadow: none;
        border: 0;
    }

    .templates .filter_item .multiselect :deep(.multiselect-clear) {
        display: none;
    }

    .templates .filter_item .multiselect :deep(.multiselect-multiple-label),
    .templates .filter_item .multiselect :deep(.multiselect-placeholder),
    .templates .filter_item .multiselect :deep(.multiselect-single-label) {
        padding-right: 30px;
        width: 100%;
        font-size: 13px;
        line-height: 18px;
        font-weight: 500;
        color: #5a5a5a;
    }

    .templates .filter_item :deep(.multiselect-dropdown) {
        bottom: -5px;
        overflow-y: overlay;
        border-radius: 5px;
        border-color: #e9e9e9;
        box-shadow: 0 1px 20px rgb(0, 0, 0, 0.15);
    }

    .templates .filter_item :deep(.multiselect-dropdown::-webkit-scrollbar) {
        width: 3px;
    }

    .templates .filter_item :deep(.multiselect-dropdown::-webkit-scrollbar-thumb) {
        background: #dbdbdb;
        border-radius: 2px;
    }

    .templates .filter_item :deep(.multiselect-dropdown ul.multiselect-options li) {
        font-size: 11px;
        line-height: 14px;
    }

    .templates .filter_search {
        height: 40px;
        border-radius: 20px;
        padding: 0 40px 0 0;
        position: relative;
        /* background: #fbfbfb; */
        background: #FFFFFF;
        flex: 0 1 300px;
        width: 100%;
        margin-bottom: 20px;
    }

    .templates .filter_search input {
        font-size: 13px;
        line-height: 40px;
        color: #666;
        border: 0;
        width: 100%;
        padding: 0 15px;
        background: transparent;
        box-sizing: border-box;
    }

    .templates .filter_search .search_btn {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 0;
        font-size: 11px;
        color: #2f7eed;
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer;
    }

    .templates .card_list {
        display: flex;
        flex-wrap: wrap;
        margin: 20px -10px 0 -10px;
    }

    .templates .card_list li {
        padding: 15px 10px;
        /* width: 33.333%; */
        /* width: 50%; */
    }

    .templates .card_list li .content_card {
        /* background: #fbfbfb; */
        background: #FFFFFF;
        border-radius: 8px;
        border: 1px solid #f5f5f5;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
    }

    .templates .card_list li .content_card:hover {
        background: #fff;
        box-shadow: 0 1px 15px rgba(0, 0, 0, 0.08);
    }

    .templates .card_list li .content_card.active {
        background: #fff;
        border-color: #e9e9e9;
        box-shadow: 0 1px 15px rgba(0, 0, 0, 0.08);
    }

    .templates .card_list li .content_card h5 {
        font-size: 16px;
        line-height: 32px;
        font-weight: 500;
        color: #121525;
        padding: 12px 20px;
        border-bottom: 1px solid #e9e9e9;
        white-space: nowrap;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .templates .card_list li .content_card .content {
        padding: 0 20px;
        max-height: 200px;
        margin: 20px 0;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        overflow: hidden;
        position: relative;
    }

    :deep(.templates .card_list li .content_card .content p) {
        font-size: 16px;
        line-height: 32px;
        font-weight: 400;
        color: #0e101a;
    }
    :deep(.templates .card_list li .content_card .content ul){
        margin-left: 20px;
        padding-left: 20px;
    }
    :deep(.templates .card_list li .content_card .content ul li){
        list-style: disc outside !important;
        padding: 10px 0;
        font-size: 16px;
        line-height: 32px;
        font-weight: 400;
        color: #0e101a;
    }

    :deep(.multiselect-search) {
        border-radius: 15px;
    }

    .card_list p.no-result {
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        margin-top: 50px;
    }

    .loader-wpr {
        background: #fff;
        border-radius: 10px;
        height: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .empty_box{
        padding: 20px 0 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-grow: 1;
        min-height: 300px;
    }
    .empty_box img {
        max-height: 90px;
        width: auto;
        max-width: 100%;
        margin-bottom: 30px;
    }
    .empty_box p{
        font-size: 15px;
        line-height: 22px;
        font-weight: 400;
        color: #2c3e50;
    }

    @media(max-width: 1499px) {
        .templates .card_list li {
            width: 50%;
        }
    }

    @media(max-width: 991px) {
        .templates .filter_bar {
            flex-direction: column;
        }

        .templates .filter_item {
            flex: 1 1 auto;
            width: 150px;
        }

        .templates .filter_search {
            flex: 1 1 auto;
        }

        .templates .card_list li {
            width: 100%;
        }
    }
</style>